<template>

	<div class="main-contents">
		<div class="mem_container">
			<div class="mem_info_contents">
				<div class="mem_contents_header">
					<div v-if="input.pageId == '1'" class="title-txt">내프로젝트 &gt; 제안단계 프로젝트<br/>
						<!-- <p><span class="prj_ct">{{projectCount.projectIngCount}}</span>개의 참여중 프로젝트와 <span class="prj_ct">{{projectCount.projectPreCount}}</span>개의 참여예정 프로젝트가 있습니다!</p> -->
					</div>
					<div v-else-if="input.pageId == '2'" class="title-txt">내프로젝트 &gt; 참여예정&middot;참여중 프로젝트<br/>
						<!-- <p><span class="prj_ct">{{projectCount.projectIngCount}}</span>개의 참여중 프로젝트와 <span class="prj_ct">{{projectCount.projectPreCount}}</span>개의 참여예정 프로젝트가 있습니다!</p> -->
					</div>
					<div v-else-if="input.pageId == '3'" class="title-txt">내프로젝트 &gt; 완료 프로젝트<br/>
						<!-- <p><span class="prj_ct">{{projectCount.projectIngCount}}</span>개의 참여중 프로젝트와 <span class="prj_ct">{{projectCount.projectPreCount}}</span>개의 참여예정 프로젝트가 있습니다!</p> -->
					</div>

					<div v-else class="title-txt">내프로젝트<br/>
						<!-- <p><span class="prj_ct">{{projectCount.projectIngCount}}</span>개의 참여중 프로젝트와 <span class="prj_ct">{{projectCount.projectPreCount}}</span>개의 참여예정 프로젝트가 있습니다!</p> -->
					</div>
				</div>
				<div class="tec_prj_tab">
					<div class="tab-type5">
						<ul class="tab">
							<li  :class="input.pageId == '1' ? 'on':''"  @click="clickTab('PRJ102M01')">
								<a>제안단계 프로젝트 </a>
								<!-- <a>제안단계 프로젝트 <span class="count">{{projectCount.projectPreCount}}건</span></a> -->
							</li>
							<li :class="input.pageId == '2' ? 'on':''" @click="clickTab('PRJ102M03')">
								<a>참여예정&middot;참여중 프로젝트 </a>
								<!-- <a>참여예정&middot;참여중 프로젝트 <span class="count">{{projectCount.projectIngCount}}건</span></a> -->
							</li> 
							<li :class="input.pageId == '3' ? 'on':''" @click="clickTab('PRJ102M04')">
								<a>완료 프로젝트 </a>
								<!-- <a>완료 프로젝트 <span class="count">{{projectCount.projectEndCount}}건</span></a> -->
							</li> 
						</ul>
					</div>
				</div>
				<div class="contents-body tecprj">
					<div class="search-box">
						<div v-if="input.pageId == '1'" class="prj_tit">제안단계 프로젝트 <span class="prj_cnt">{{projectCount.projectPreCount}}건</span></div>
						<div v-if="input.pageId == '2'" class="prj_tit">참여예정&middot;참여중 프로젝트 <span class="prj_cnt">{{projectCount.projectIngCount}}건</span></div>
						<div v-if="input.pageId == '3'" class="prj_tit">완료 프로젝트 <span class="prj_cnt">{{projectCount.projectEndCount}}건</span></div>

						<SelectComp type="select" class="select_wrap prj_name" list="1:프로젝트명,2:수행사명" v-model="input.searchGubun"/>						
						<InputComp type="text" classNm="search_box int" :placeholder="input.searchGubun == '1' ? '프로젝트명' : '수행사명'" v-model="input.searchKeyword" @keyup.enter="getList('1')"  />
						<div class="search_btn" ><img class="search" src="/images/search_btn.png" alt="검색버튼" @click="getList('1')"/></div>
					</div>

					<div class="filter">
						<div class="filter_box">
							<ul>
							<li><p class="text">NO.</p></li>
							</ul>
						</div>
					</div>

					<div v-for="(project, index) in projectList" :key="project.projectSeq" class="project_box">
						<div class="no">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</div>
						<div class="prj_pf box-hover">
							<div v-if="project.projectRecrStatus == '1'" class="state_mark">참여중</div>
							<div v-if="project.projectRecrStatus == '2'" class="state_mark ep">참여예정</div>
							<div v-if="project.projectRecrStatus == '9'" class="state_mark cp">완료</div>
							<div v-if="project.projectRecrStatus == '0'" class="state_mark bp">제안단계</div>

							<div class="prj_contents" @click="clickProjectInfo(project.projectSeq)">
								<div class="regi">
									<div class="tit int_prj sco_mark" v-if="project.projectDivCd == '31' || project.projectDivCd == '32'">공급기업 지정</div>
									<div class="tit int_prj" v-else>프로젝트명</div>
									<div class="name prj-tit-hover" style="width: 460px;">{{project.projectNm}}</div>
									<div class="int_box">
										<div class="list-view int_prj">
											<div class="label ivl03">개요</div>
											<div class="list text">{{project.projectDtlDesc}}</div>
										</div>
										<div class="list-view int_prj">
											<div class="label ivl04">고객사</div>
											<div class="list">
												<div class="add">{{project.clientNm}}</div>
												<div class="loca"><img src="/images/location.png" alt="위치" />{{project.projectLocCont}}</div>
											</div>
										</div>
										<div class="list-view int_prj">
											<div class="label ivl05">산업분야</div>
											<div class="list" style="display: inline-block !important;"><SelectComp type="text" cdId="PRO105" :value="project.indtypeFilterCd"/> / <SelectComp type="text" cdId="PRO143" :value="project.indtypeClassCd"/></div>
										</div>
									</div>
								</div>
								<div class="int_box">                                            
									<div class="list-view int_prj">
										<div class="label ivl04">수행사</div>
										<div class="list text">{{project.corpNm}}</div>
									</div>
									<div class="list-view int_prj">
										<div class="label ivl05">개발기간</div>
										<div class="list">{{project.projectStartEndYyyymmdd}}</div>
									</div>
									<div class="list-view int_prj">
										<div class="label ivl05">개발기술</div>
										<div class="list text">{{project.devTechCont}}</div>
									</div>                                           
								</div>
							</div>
							<div class="prj_rate" v-if="input.pageId == 1">
								<div class="tit">프로젝트 참여율</div>
								<div class="rate">0%</div>
								<div class="rate_img"><img :src="'/images/tec/prj/' + 0 + '_percent.png'" alt="수행율이미지"/></div>
								<div class="ptcp_period">
									<div class="tit">참여기간</div><span class="date">{{project.joinStartYyyymmdd | date('yyyy.MM.dd')}} - {{project.joinEndYyyymmdd | date('yyyy.MM.dd')}}</span>
									<div class="tit">참여소속</div><span class="date">{{project.projectJoinAsgNm }}</span>
								</div>		
							</div>
							<ProjectRecrRate v-else type="MyPrj" :recrStartYyyymmdd="project.joinStartYyyymmdd" :recrEndYyyymmdd="project.joinEndYyyymmdd" :projectJoinAsgNm="project.projectJoinAsgNm"/>
						</div>
					</div>

					 <!-- 관련 내용이 없을 경우 -->
					 <div v-if="pageInfo.totalRecordCount == 0" class="project_box">
						<div class="prj_pf none">
							관련 내용이 존재하지 않습니다!
						</div>
					</div>

					<!--페이징 컴포넌트 -->
					<PagingComp :pageInfo="pageInfo" @page-click="goPage"/>
					<!--// 페이징 컴포넌트 -->

				</div>
			</div>
		</div>
	</div>

</template>

<script>

import PagingComp from '@/components/PagingComp.vue';
import ProjectRecrRate from '@/components/highpro/ProjectRecrRate.vue';

export default {

	components : {
		PagingComp,
		ProjectRecrRate
	},

	data() {
		return {
			input: {
				pageIndex : '1',
				searchGubun : '1',	// 1:프로젝트명, 2:수행사명,
				searchKeyword : '',
				pageId : this.$route.name || '' ,
			}, // 검색조건		

			userInfo : {
				birthYyyymmdd : ''
			},
			projectList : [],
			pageInfo : {},
			projectCount : {},
		};
	},
	bforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
		// console.log("!!pageId : " + this.input.pageId);
		if(this.input.pageId == 'PRJ102M01'){
            this.input.pageId = '1';
        }else if(this.input.pageId == 'PRJ102M03'){
            this.input.pageId = '2';
        }
		else{
            this.input.pageId = '3';
        }

		// console.log(window);
	},
	mounted() {
		// console.log('mounted');

		this.userInfo = this.$store.state.userInfo;

		this.getList();
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		
		getList(div) {
			if(div) this.input.pageIndex = 1;
			
			// console.log("pageId : " + this.input.pageId);
			this.$.httpPost('/api/prj/tec/getMyProjectList', this.input)
				.then(res => {
					// console.log(res.data);
					this.projectList 	= res.data.list;
					this.pageInfo 		= res.data.pageInfo;
					this.projectCount	= res.data.projectCount;

					window.scroll(0, 0);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		},

		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},

		clickProjectInfo(projectSeq) {
			var param = {
				projectSeq : projectSeq
			};
			this.$.popup('/tec/prj/PRJ102P01', param, {width:0, height:0})
				// .then(res => {})
				// .catch(err => {})
				;
		},

		clickTab(page){
			if(this.$route.name != page){
				this.$router.push({name: page});
			}
			
		},
	},
};
</script>
